import React, { useState } from 'react';

const AppContext = React.createContext();

const AppContextProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <AppContext.Provider
      value={{
        isMenuOpen,
        setIsMenuOpen
      }}
    >
      { children }
    </AppContext.Provider>
  );
}

export default AppContext;

export { AppContextProvider }
